import { useQueryString } from 'global/utils';
import Cookies from 'js-cookie';
import type { CookieCampaignInfo } from './segment';
import { parse_utm_querystring_to_object } from './segment';

/**
 * @deprecated all this code is temporary until we update all the data to deprecate localStorage
 */
function get_old_values_from_local_storage() {
  if (typeof window !== 'undefined' && window.localStorage) {
    try {
      const stored_data = localStorage.getItem('campaignInfo');
      const parsed_stored_data = stored_data ? JSON.parse(stored_data) : null;
      localStorage.removeItem('campaignInfo');
      if (!parsed_stored_data) return null;
      return (
        Object.entries(parsed_stored_data)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_, value]) => value != null)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      ); // Join with &
    } catch (error) {
      return null;
    }
  }
  return null;
}

/**
 * This implementation works in the same way in terminal-io / web-customer
 */
export function select_utm_cookies_object() {
  const utm_cookie_params: string = Cookies.get('utm_cookie_params') ?? '';
  return parse_utm_querystring_to_object(utm_cookie_params);
}

/**
 * This implementation works in the same way in terminal-io / web-customer
 * We can refactor it to be shared (just keep in mind next-router vs react-router)
 */
export function useSetUtmCookies() {
  const queryString = useQueryString();
  // * Trying to follow same structure as terminal.io, to improve them together in the future
  const query = {
    utm_campaign: queryString.get('utm_campaign'),
    utm_medium: queryString.get('utm_medium'),
    utm_source: queryString.get('utm_source'),
    utm_content: queryString.get('utm_content'),
    utm_term: queryString.get('utm_term'),
    cid: queryString.get('cid'),
    tracking_id: queryString.get('tracking_id'),
  };

  const cookies_api = Cookies.withAttributes({
    expires: 365,
    // * To make it works at localhost
    domain: window.location.hostname === 'localhost' ? undefined : '.terminal.io',
  });

  // TODO: all this conditional are temporary until we deprecate localStorage and lastTouchParams cookie
  const utm_old_cookie_params = Cookies.get('lastTouchParams');

  if (utm_old_cookie_params) {
    cookies_api.set('utm_cookie_params', utm_old_cookie_params);
    Cookies.remove('lastTouchParams', { path: '/' });
  } else {
    const old_campaign_info_in_session_from_local_storage = get_old_values_from_local_storage();
    if (old_campaign_info_in_session_from_local_storage) {
      cookies_api.set('utm_cookie_params', old_campaign_info_in_session_from_local_storage);
    }
  }
  // TODO: end of temporary conditional

  const has_utm_params_in_query = !!(
    query.utm_campaign ||
    query.utm_medium ||
    query.utm_source ||
    query.utm_content ||
    query.utm_term ||
    query.cid
  );

  if (!(has_utm_params_in_query || query.tracking_id)) return;

  const current_utm_params = select_utm_cookies_object();

  let new_utm_params_to_save: ReturnType<typeof select_utm_cookies_object> | typeof query = {};

  const tracking_id = {
    ...(query.tracking_id || current_utm_params.tracking_id
      ? { tracking_id: query.tracking_id || current_utm_params.tracking_id }
      : {}),
  };

  if (has_utm_params_in_query) {
    new_utm_params_to_save = {
      ...query,
      ...tracking_id,
    };
  } else {
    new_utm_params_to_save = {
      ...current_utm_params,
      ...tracking_id,
    };
  }

  const utm_params_names: (keyof CookieCampaignInfo)[] = [
    'utm_campaign',
    'utm_medium',
    'utm_source',
    'utm_content',
    'utm_term',
    'cid',
    // * This is not a utm param, but we're using it as one
    'tracking_id',
  ];

  const utm_params_query_string = utm_params_names
    .map((key) => (new_utm_params_to_save[key] ? `${key}=${new_utm_params_to_save[key]}` : ''))
    .filter(Boolean)
    .join('&');

  if (utm_params_query_string) {
    cookies_api.set('utm_cookie_params', utm_params_query_string);
  }
}

/**
 * This implementation works in the same way in terminal-io / web-customer
 */
export function useGetUtmCookies() {
  const utm_cookie_params: string = Cookies.get('utm_cookie_params') ?? '';
  return utm_cookie_params ? `?${utm_cookie_params}` : '';
}
